export const tabsNav = {
    auction_houses: {
        id: 'auction_houses',
        name: 'Аукционные дома',
        template: 'auction_houses',
        pathName: 'auction_houses',
        icon: 'store',
        iconSize: '16',
    },
    painters: {
        id: 'painters',
        name: 'Авторы',
        template: 'painters',
        pathName: 'painters',
        icon: 'pencil',
        iconSize: '16',
    },
    galleries: {
        id: 'galleries',
        name: 'Галереи',
        template: 'galleries',
        pathName: 'galleries',
        icon: 'bank',
        iconSize: '16',
    },
};
