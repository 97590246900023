
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';

import SellersModule from '@/admin/store/sellers';
import GalleriesModule from '@/admin/store/sellers/galleries';

@Component({
    components: {
        Layout,
        FlexLayout,
        Loading,
        TableBlock,
        AppHeaderMain,
        TabsNav,
        TitleReturn,
    },
})
export default class GalleriesComponent extends Vue {
    get settings() {
        return GalleriesModule.pageSettings;
    }

    get filterSettings() {
        return GalleriesModule.filter.filterSettings;
    }

    get isLoading(): boolean {
        return GalleriesModule.isLoading;
    }

    get tabsNav() {
        return SellersModule.tabsNav;
    }

    get currentTab() {
        return SellersModule.allTabs.galleries.id;
    }

    get paginationAction() {
        return GalleriesModule.updatePage;
    }

    get paginationItems(): { action: (value: string | number) => void; items: string } {
        return {
            action: GalleriesModule.updateItems,
            items: GalleriesModule.pageSettings.pageAmountItems,
        };
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            GalleriesModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        GalleriesModule.getList();
    }

    async resetFilters() {
        await GalleriesModule.clearSort();
        GalleriesModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'gallery_create' });
    }

    mounted() {
        GalleriesModule.init();
        SellersModule.initTabsItems();
    }

    beforeDestroy(): void {
        GalleriesModule.reset();
    }
}
